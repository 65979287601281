import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadConfettiPreset } from "tsparticles-preset-confetti";
import '../assets/css/particle.css';

const options = {
    "preset": "confetti",
    "fullScreen": {
        "zIndex": 1
    },
    "particles": {
        "color": {
            "value": [
                "#FFFFFF",
                "#FFd700",
                "#DD673C",
                "#94C11F",
                "#738FC9",
            ]
        },
        "move": {
            "direction": "bottom",
            "enable": true,
            "outModes": {
                "default": "out"
            },
            "size": true,
            "speed": {
                "min": 1,
                "max": 3
            }
        },
        "number": {
            "value": 500,
            "density": {
                "enable": true,
                "area": 800
            }
        },
        "opacity": {
            "value": 1,
            "animation": {
                "enable": false,
                "startValue": "max",
                "destroy": "min",
                "speed": 0.3,
                "sync": true
            }
        },
        "rotate": {
            "value": {
                "min": 0,
                "max": 360
            },
            "direction": "random",
            "move": true,
            "animation": {
                "enable": true,
                "speed": 60
            }
        },
        "tilt": {
            "direction": "random",
            "enable": true,
            "move": true,
            "value": {
                "min": 0,
                "max": 360
            },
            "animation": {
                "enable": true,
                "speed": 60
            }
        },
        "shape": {
            "type": [
                "square",
                "triangle",
                "polygon",

            ],
            "options": {
                "polygon": [
                    {
                        "sides": 5
                    },
                    {
                        "sides": 4
                    }
                ]
            }
        },
        "size": {
            "value": {
                "min": 2,
                "max": 4
            }
        },
        "roll": {
            "darken": {
                "enable": true,
                "value": 30
            },
            "enlighten": {
                "enable": true,
                "value": 30
            },
            "enable": true,
            "speed": {
                "min": 15,
                "max": 25
            }
        },
        "wobble": {
            "distance": 30,
            "enable": true,
            "move": true,
            "speed": {
                "min": -15,
                "max": 15
            }
        }
    }
};

function Particle() {
    const particlesInit = useCallback(async engine => { 
        await loadConfettiPreset(engine);
    }, []);
    const particlesLoaded = useCallback(async container => { }, []);

    return (<Particles id="tsparticles" options={options} init={particlesInit} loaded={particlesLoaded} />)
}

export default Particle
