import React from 'react'
import mrvc from '../assets/images/mrvc.png'
import rodic from '../assets/images/rodic.png'
import spdl from '../assets/images/spdl.png'
import siso from '../assets/images/siso.png'
import '../assets/css/content.css'
import Particle from './particle'

function Content() {
    return (
        <>
            <div className='content-container'>
                <h1 className='heading mainHeading'>Launching Ceremony</h1>
                <p>of</p>
                <div className='imgContainer'>
                    <img src={siso} className='logo' alt='' />
                    <img src={mrvc} className='logo' alt='' />
                    <img src={spdl} className='logo' alt='' />
                    <img src={rodic} className='logo' alt='' />
                </div>
                <h1 className='subheading'>SISO WEB-BASED PROJECT MANAGEMENT INFORMATION SYSTEM (PMIS)</h1>
                <p>Implemented on</p>
                <h2 className='paragraph '>"Construction of Three Tunnels by NATM with Ballast less Track in Panvel - Karjat Section in connection with Panvel - Karjat double line Suburban Corridor Project of Mumbai Division of Central Railway in the State of Maharashtra, India"</h2>
                <h1 className='heading subheading'>Inauguration by</h1>
                <div className='boxContainer'>
                    <div className='box'>
                        <h3>Sh. Subhash Chand Gupta</h3>
                        <p>Chairman & Managing Director (MRVC)</p>
                    </div>
                </div>
                <h1 className='heading subheading'>In the Presence of</h1>
                <div className='boxContainer'>
                    <div className='box'>
                        <h3>Sh. Rajeeb Kumar Shrivastava</h3>
                        <p>Director Project (MRVC)</p>
                    </div>
                    <div className='box'>
                        <h3>Sh. Kanahyia Jha</h3>
                        <p>Chief Project Manager (MRVC)</p>
                    </div>
                </div>

                <p>25<sup>th</sup> September 2023 at Site office, conference room</p>
            </div>
            <Particle />
        </>
    )
}

export default Content
