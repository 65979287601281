import React, { useState } from 'react'
import '../assets/css/new-curtain.scss';
import btn from '../assets/images/btn.png'
import Content from './content';
import { REDIRECT_URL } from '../config'

function Curtain() {
    const [curtainOpened, setCurtainOpened] = useState(false);
    const [curtainRemoved, setCurtainRemoved] = useState(false);

    const handleClick = () => {
        setCurtainOpened(true);
        setTimeout(() => {
            setCurtainRemoved(true);
        }, 3000);
    }

    return (
        <>
            <a className="content" href={REDIRECT_URL} target='_blank' rel="noreferrer">
                <Content />
            </a>
            <div className={`btnContainer ${curtainOpened && 'opened'}`}>
                <img src={btn} className='btn' alt='' onClick={handleClick} />
            </div>

            {!curtainRemoved && (<div className={`curtainBody ${curtainOpened && 'opened'} ${curtainRemoved && 'remove'}`}>
                <div id="leftCurtain" className="curtainContainer">
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                </div>
                <div id="rightCurtain" className="curtainContainer">
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                    <div className="unCurtain"></div>
                </div>
                {/* <div className="overlay"></div> */}
            </div>)}
        </>
    )
}

export default Curtain
